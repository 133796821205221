import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AmountForm from './AmountForm';
import PaymentForm from './PaymentForm';
import useScript from './useScript';

function base64ToBase10(base64String) {
  const order =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_-';
  const base = order.length;
  let num = 0;
  let str = base64String;
  while (str.length) {
    const r = order.indexOf(str.charAt(0));
    str = str.substr(1);
    num *= base;
    num += r;
  }
  return parseInt(num, 10);
}

const donationId = base64ToBase10(window.location.pathname.substr(1));

const Copyright = () => (
  <Typography variant="body2" color="text.secondary" align="center">
    {'Copyright © '}
    {new Date().getFullYear()} ClearCourse Business Services Limited t/a
    instaGiv.
  </Typography>
);

const steps = ['Amount', 'Payment details', 'Complete'];

const theme = createTheme({
  palette: {
    primary: {
      main: '#42b2e0',
      contrastText: '#ffffff',
    },
  },
});

export default function App() {
  const [loading, setLoading] = React.useState(true);
  const [fieldToken, setFieldToken] = React.useState('');
  const [charityName, setCharityName] = React.useState(null);
  const [originalAmount, setOriginalAmount] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [hostedFields, setHostedFields] = React.useState(null);
  const [accountHolderName, setAccountHolderName] = React.useState('');
  const [token, setToken] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  useScript(process.env.REACT_APP_HOSTED_FIELDS_SCRIPT_URL);

  const expireFieldToken = React.useCallback(
    (expiredToken) => {
      if (fieldToken === expiredToken) {
        setFieldToken('');
        if (!token) {
          setErrorMessage(
            'The session has expired. No payment has been taken.',
          );
        }
      }
    },
    [token, fieldToken],
  );

  // Temporary tokens have a lifetime of 15 minutes
  React.useEffect(() => {
    let intervalCheck = null;
    if (fieldToken) {
      const date = new Date();
      date.setSeconds(date.getSeconds() + 15 * 60 - 5);
      intervalCheck = setInterval(() => {
        const now = new Date();
        if (now > date) {
          expireFieldToken(fieldToken);
        }
      }, 5000);
    }
    return () => {
      if (intervalCheck) {
        clearInterval(intervalCheck);
      }
    };
  }, [fieldToken, expireFieldToken]);

  // Initialise the hosted fields when we have the correct temporary token
  React.useEffect(() => {
    if (!loading && activeStep === 1 && fieldToken && window.HostedFields) {
      const hostedFieldOptions = {
        fields: {
          card: {
            containerElementId: 'card-wrapper',
          },
          expdate: {
            containerElementId: 'expdate-wrapper',
          },
          cvv: {
            containerElementId: 'cvv-wrapper',
          },
        },
        style: {
          logoAlignment: 'right',
          placeholderText: {
            cardNumber: 'Card number',
            expiryDate: 'MM / YY',
            cvv: 'CVV',
          },
          base: {
            borderColor: '#c4c4c4',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '4px',
            backgroundColor: 'white',
            fontSize: '16px',
            fontColor: 'rgba(0,0,0,0.8)',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 'normal',
            textAlign: 'left',
            padding: '13px',
            focus: {
              borderWidth: '2px',
              borderColor: '#42b2e0',
              padding: '12px',
            },
            hover: {
              borderColor: '#212121',
            },
          },
        },
      };

      // just in case there are any old iframe elements
      ['card', 'expdate', 'cvv'].forEach((fieldName) => {
        const parent = document.getElementById(
          hostedFieldOptions.fields[fieldName].containerElementId,
        );
        while (parent.lastChild) {
          parent.removeChild(parent.lastChild);
        }
      });

      window.HostedFields.initialise(
        fieldToken,
        hostedFieldOptions,
        (error, fields) => {
          if (error) {
            setErrorMessage('Unknown error');
            return;
          }
          setHostedFields(fields);
        },
      );
    }
  }, [fieldToken, activeStep, loading]);

  // Make failed donation API calls
  React.useEffect(() => {
    const failedDonationApi = `${process.env.REACT_APP_IG2V2_URL}/payment/failed-donation`;
    let isCancelled = false;
    if (loading) {
      if (token !== '') {
        const body = JSON.stringify({
          token,
        });
        fetch(`${failedDonationApi}/${donationId}/confirm`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body,
        })
          .then((response) => {
            if (!isCancelled) {
              if (response.status === 200) {
                return response.json();
              }
              return Promise.reject(response);
            }
            return [];
          })
          .then((responseData) => {
            if (responseData.data) {
              if (responseData.data.status === 'confirmed') {
                setActiveStep(2);
              }
            }
          })
          .catch((response) => response.json())
          .then((responseData) => {
            if (responseData) {
              setErrorMessage(responseData.error_text);
            }
          });
      } else {
        const body = JSON.stringify({
          amount,
        });
        fetch(`${failedDonationApi}/${donationId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body,
        })
          .then((response) => {
            if (!isCancelled) {
              if (response.status === 200) {
                return response.json();
              }
              return Promise.reject(response);
            }
            return [];
          })
          .then((responseData) => {
            if (responseData.data) {
              setLoading(false);
              setFieldToken(responseData.data.field_token);
              setCharityName(responseData.data.charity_name);
              if (amount === 0) {
                setOriginalAmount(responseData.data.amount);
              }
              setAmount(responseData.data.amount);
            }
          })
          .catch((response) => response.json())
          .then((responseData) => {
            if (responseData) {
              setErrorMessage(responseData.error_text);
            }
          });
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [loading, amount, token]);

  const handlePayment = () => {
    const data = {
      accountHolderName,
    };
    setFieldToken('');
    hostedFields.setData(data);
    hostedFields.tokenize((newToken) => {
      if (newToken.TokenId) {
        setToken(newToken.TokenId);
        setLoading(true);
      } else {
        setErrorMessage('Unknown error');
      }
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AmountForm
            amount={originalAmount}
            setAmount={(newAmount) => {
              if (amount !== newAmount) {
                setAmount(newAmount);
                setFieldToken(null);
                setLoading(true);
              }
              setActiveStep(1);
            }}
          />
        );
      case 1:
        return !loading ? (
          <PaymentForm
            accountHolderName={accountHolderName}
            setAccountHolderName={setAccountHolderName}
            hostedFields={hostedFields}
            handleBack={() => setActiveStep(0)}
            handlePayment={() => handlePayment()}
          />
        ) : (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress size={40} />
          </div>
        );
      case 2:
        return (
          <Typography variant="subtitle1" align="center">
            Your payment of £
            {(amount / 100).toLocaleString('en-GB', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            to {charityName} is now being processed.
          </Typography>
        );
      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            {charityName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        {errorMessage && (
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 } }}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          </Paper>
        )}
        {!errorMessage && (
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography
              component="h1"
              variant="h4"
              align="center"
              style={{ fontFamily: 'Roboto Slab, Roboto, sans-serif' }}
            >
              {activeStep === 2 ? 'Thank you!' : 'Complete your donation'}
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {getStepContent(activeStep)}
          </Paper>
        )}
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
