import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

const ButtonSkeleton = () => (
  <Skeleton
    variant="rectangular"
    width="100%"
    height={42.25}
    style={{ borderRadius: 4 }}
  />
);

const AmountForm = (props) => {
  const { amount, setAmount } = props;

  const GiveButton = (buttonProps) => {
    const { amount: giveAmount, extra, variant } = buttonProps;
    return (
      <Button
        fullWidth
        size="large"
        variant={variant || 'outlined'}
        onClick={() => setAmount(giveAmount)}
      >
        {extra} £
        {(giveAmount / 100).toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Button>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {amount === 0 ? (
            <ButtonSkeleton />
          ) : (
            <GiveButton amount={amount} extra="Give" variant="contained" />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            or increase your donation to:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          {amount === 0 ? (
            <ButtonSkeleton />
          ) : (
            <GiveButton amount={amount * 2} />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {amount === 0 ? (
            <ButtonSkeleton />
          ) : (
            <GiveButton amount={amount * 3} />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {amount === 0 ? (
            <ButtonSkeleton />
          ) : (
            <GiveButton amount={amount * 4} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

AmountForm.defaultProps = {
  amount: 0,
};

AmountForm.propTypes = {
  amount: PropTypes.number,
  setAmount: PropTypes.func.isRequired,
};

export default AmountForm;
