import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Mastercard from './Mastercard.svg';
import Visa from './Visa.svg';

const PaymentForm = (props) => {
  const {
    accountHolderName,
    setAccountHolderName,
    hostedFields,
    handleBack,
    handlePayment,
  } = props;

  const [formStatus, setFormStatus] = React.useState(null);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);

  const handleNameChange = (event) => {
    if (event.target.value.length <= 45) {
      setAccountHolderName(event.target.value);
    }
  };

  const onPayButtonPress = (event) => {
    event.preventDefault();
    if (submitDisabled) {
      return;
    }
    setSubmitDisabled(true);
    handlePayment();
  };

  const formatErrorMessage = (fieldName) => {
    if (
      formStatus === null ||
      formStatus[fieldName] === undefined ||
      formStatus[fieldName].isValid ||
      (formStatus[fieldName].isPristine && formStatus.isNotSubmited)
    ) {
      return '';
    }
    return formStatus[fieldName].message;
  };

  React.useEffect(() => {
    if (hostedFields) {
      hostedFields.on('change', (status) => {
        setFormStatus({ ...status });
      });
    }
  }, [hostedFields]);

  React.useEffect(() => {
    if (formStatus) {
      let validForm = true;
      ['card', 'expDate', 'cvv'].forEach((fieldName) => {
        if (!formStatus[fieldName].isValid) {
          validForm = false;
        }
      });
      setSubmitDisabled(!validForm || accountHolderName.length < 2);
    }
  }, [formStatus, accountHolderName]);

  const wrapperStyle = {
    width: '100%',
    height: '56px',
    position: 'absolute',
    top: 0,
  };

  const errorStyle = {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '0.75rem',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    textAlign: 'left',
    marginTop: '3px',
    marginRight: '14px',
    marginBottom: '-20px',
    marginLeft: '14px',
    height: '20px',
  };

  const InputSkeleton = () => (
    <Skeleton variant="rectangular" height={56} style={{ borderRadius: 4 }} />
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id="cardName"
            label="Name on card"
            fullWidth
            autoComplete="cc-name"
            variant="outlined"
            value={accountHolderName}
            onChange={handleNameChange}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ position: 'relative' }}>
            <InputSkeleton />
            <div id="card-wrapper" style={wrapperStyle} />
          </div>
          <div id="card-errors" style={errorStyle}>
            {formatErrorMessage('card')}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ position: 'relative' }}>
            <InputSkeleton />
            <div id="expdate-wrapper" style={wrapperStyle} />
          </div>
          <div id="expdate-errors" style={errorStyle}>
            {formatErrorMessage('expDate')}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ position: 'relative' }}>
            <InputSkeleton />
            <div id="cvv-wrapper" style={wrapperStyle} />
          </div>
          <div id="cvv-errors" style={errorStyle}>
            {formatErrorMessage('cvv')}
          </div>
        </Grid>
        <Grid item xs={12} md={6} align="center">
          <img
            src={Mastercard}
            alt="Mastercard"
            style={{ margin: '-10px 0', width: 70 }}
          />
          <img src={Visa} alt="Visa" style={{ margin: '-10px 0', width: 70 }} />
        </Grid>
        <Grid item xs={12} md={6} align="center" sx={{ margin: 'auto 0' }}>
          <Button onClick={handleBack} sx={{ mt: 0.5, mb: 0.5 }}>
            Back
          </Button>
          <Button
            onClick={onPayButtonPress}
            variant="contained"
            sx={{ ml: 1, mt: 0.5, mb: 0.5 }}
            disabled={submitDisabled}
          >
            Make Donation
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

PaymentForm.defaultProps = {
  accountHolderName: '',
  hostedFields: null,
};

PaymentForm.propTypes = {
  accountHolderName: PropTypes.string,
  setAccountHolderName: PropTypes.func.isRequired,
  hostedFields: PropTypes.objectOf(PropTypes.any),
  handleBack: PropTypes.func.isRequired,
  handlePayment: PropTypes.func.isRequired,
};

export default PaymentForm;
